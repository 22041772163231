import React from "react";
import Sidebar from "./Sidebar";
import { Box, Divider, Typography } from "@mui/material";

const Layout = ({ title, children }) => {
	const layoutStyle = {
		display: "flex",
		flexDirection: { xs: "column", sm: "row" },
		my: 4,
		ml: 2,
	};

	return (
		<Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
			<Sidebar />
			<Box sx={layoutStyle}>
				<Box component="main" sx={{ width: { xs: `calc(100vw - 50px)`, sm: `calc(100vw - 240px - 50px)` } }}>
					<Typography sx={{ fontSize: 28, fontWeight: "bold" }}>{title}</Typography>
					<Divider sx={{ my: 1 }} />
					{children}
				</Box>
			</Box>
		</Box>
	);
};

export default Layout;
