import { MuiMarkdown } from "mui-markdown";

export const MarkdownExtensions = {
	Font: {
		component: ({ children, size, align }) => {
			return <span style={{ fontSize: parseInt(size), textAlign: align }}>{children}</span>;
		},
	},
};

export const Markdown = ({ children }) => {
	return <MuiMarkdown overrides={MarkdownExtensions}>{children}</MuiMarkdown>;
};
