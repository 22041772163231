import React from "react";
import { Box, Divider, Typography, Stack, Button } from "@mui/material";
import CustomIcon from "./CustomIcon";

import { faGithub } from "@fortawesome/free-brands-svg-icons";

const ProjectCard = ({ project }) => {
	return (
		<Box sx={{ border: 2, borderRadius: 2 }}>
			<Stack direction={{ sm: "column", md: "row" }} justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
				<Typography sx={{ fontSize: 25, textAlign: { sm: "justify", md: "left" } }}>{project.title}</Typography>
				<Typography sx={{ px: 1, py: 0.3, borderRadius: 1, backgroundColor: "#E7E7E7", fontStyle: "italic", fontSize: 13 }}>{project.date}</Typography>
			</Stack>
			<Divider />
			<Box sx={{ p: 2 }}>
				<Typography sx={{ textAlign: "justify" }}>{project.description}</Typography>
				<Stack direction="row" spacing={1} sx={{ mt: 2 }} useFlexGap flexWrap="wrap">
					{project.techStack.map((tech) => (
						<Typography key={tech} sx={{ px: 1, py: 0.3, borderRadius: 1, backgroundColor: "#E7E7E7", fontSize: 13 }}>
							{tech}
						</Typography>
					))}
				</Stack>
			</Box>
			<Divider />
			<Stack direction="row" spacing={1} sx={{ p: 2 }}>
				<Button variant="outlined" href={project.githubLink} target="_blank">
					<CustomIcon icon={faGithub} size="lg" sx={{ mr: 2 }} />
					Source on GitHub
				</Button>
			</Stack>
		</Box>
	);
};

export default ProjectCard;
