import React from "react";
import { Box, Divider, Typography, Stack } from "@mui/material";

const PublicationCard = ({ sx }) => (
	<Box sx={{ border: 2, borderRadius: 2, ...sx }}>
		<Box sx={{ p: 2 }}>
			<Typography sx={{ fontSize: 25 }}>Publications</Typography>
		</Box>

		<Divider />

		<Box sx={{ p: 2 }}>
			<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
				<Typography sx={{ fontSize: 13, textAlign: "justify" }}>
					Z. T. Cankiri, E. E. Marasli, S. Akturk, S. Sonlu, and U. Gudukbay, “Guido: Augmented Reality for Indoor Navigation Using Commodity Hardware,” in{" "}
					<span style={{ fontStyle: "italic" }}>
						2020 24<span className="superscript">th</span> International Conference Information Visualisation (IV)
					</span>
					, 2020, doi: 10.1109/iv51561.2020.00123 [Online]. Available:
					<a href="http://dx.doi.org/10.1109/IV51561.2020.00123">http://dx.doi.org/10.1109/IV51561.2020.00123</a>
				</Typography>
			</Stack>
		</Box>
	</Box>
);

export default PublicationCard;
