import React from "react";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomIcon = ({ icon, size, sx }) => {
	return (
		<Box
			sx={{
				width: 2,
				height: 2,
				alignItems: "center",
				display: "flex",
				justifyContent: "center",
				...sx,
			}}
		>
			<FontAwesomeIcon icon={icon} size={size} />
		</Box>
	);
};

export default CustomIcon;
