import React from "react";
import { IconButton } from "@mui/material";
import CustomIcon from "./CustomIcon";

const CustomIconButton = ({ icon, color, size, iconSize, component, href, to }) => {
	const componentProps = component === "a" ? { href, target: "_blank" } : { to };

	return (
		<IconButton
			size={size}
			sx={{
				color: color,
				borderColor: color,
				border: 1,
				padding: 2,
				mr: 0.5,
			}}
			component={component}
			{...componentProps}
		>
			<CustomIcon icon={icon} size={iconSize} />
		</IconButton>
	);
};

export default CustomIconButton;
