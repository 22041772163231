import React from "react";
import { Stack, Typography } from "@mui/material";

import Layout from "../components/Layout";
import ProjectCard from "../components/ProjectCard";

const projects = [
	{
		title: "Bunket-Net-Sim",
		description:
			"A messaging app simulation was developed at the Technical University of Munich as part of a Master Practical Course project, specifically designed for individuals stuck in bunkers after a catastrophic event such as nuclear warfare. The simulation utilized advanced technologies such as OMNeT++, INET, and Simu5G, which allowed for the creation of a highly robust messaging app capable of functioning under extreme conditions. The project also focused on the importance of computer networking in enabling seamless data transfer between devices. Overall, this simulation represents a significant development in network simulation, with potential applications in disaster relief scenarios.",
		techStack: ["OMNeT++", "C++", "Python"],
		githubLink: "https://github.com/ztancankiri/bunker-net-sim",
		date: "2023",
	},
	{
		title: "ReadyMeal",
		description:
			"ReadyMeal is an online platform to help restaurant customers reserve a table in a restaurant and eliminate their waiting time for meal preparation with a vast selection of available meals prepared in advance for their reservations.",
		techStack: ["React", "Redux", "Node.js", "Docker"],
		githubLink: "https://github.com/ztancankiri/ready-meal",
		date: "2022",
	},
	{
		title: "Guido",
		description:
			"Guido is a cross-platform mobile application that uses AR technology to create floor plans. Once the floor plan is created, users can place a generic 3D humanoid agent in fixed locations to serve visitors with information about the entire room. The 3D agent is also used by visitors of the establishment as an indoor navigator to find certain items, goods, or exhibitions. The application was developed using Unity and Unity's AR Foundation Framework.",
		techStack: ["Unity", "Node.js", "Python", "PostgreSQL", "Docker", "Nginx", "WebSockets"],
		githubLink: "https://ztancankiri.github.io/guido",
		date: "2020",
	},
	{
		title: "Smart Roads",
		description:
			"Smart Roads is an innovative IoT project that dynamically adjusts speed limits based on traffic density and environmental conditions. Smart traffic signs display the optimal speed limit to drivers helping improve road safety and making traffic control more efficient. Smart Roads is a cutting-edge solution that utilizes IoT technology to create smarter, safer, and more efficient roadways.",
		techStack: ["JavaScript", "React", "Node.js", "PostgreSQL"],
		githubLink: "https://github.com/ztancankiri/smart-roads",
		date: "2020",
	},
	{
		title: "Together",
		description:
			"This is a social media platform created for the CS 353 Database Systems course project at Bilkent University. It serves as an event-hosting platform similar to Meetup.com. Built with Node.js and React.js, it uses MySQL for fast and efficient data storage and retrieval. The platform has an intuitive interface and powerful functionality, making it ideal for event organizers who want to connect with like-minded individuals and create a strong community around shared interests. It represents cutting-edge of social media technology and provides users with a user-friendly tool for hosting and promoting events.",
		techStack: ["Node.js", "React", "MySQL"],
		githubLink: "https://github.com/ztancankiri/Together",
		date: "2019",
	},
	{
		title: "NY-Times Mini Crossword Solver",
		description:
			"This crossword solver is powered by smart algorithms that utilize the Natural Language Toolkit for advanced semantic analysis. By crawling data from Google searches, the solver quickly provides accurate answers to complex crossword puzzles. Developed as part of the CS 461 Artificial Intelligence course project at Bilkent University, this solver represents cutting-edge AI-powered technology. It is a powerful and user-friendly tool for solving challenging crossword puzzles, making it a major breakthrough in the field of artificial intelligence.",
		techStack: ["Python", "Java", "NLT"],
		githubLink: "https://github.com/user/project1",
		date: "2018",
	},
	{
		title: "Q-Bitz",
		description:
			"Q-Bitz is a multiplayer puzzle game that challenges players to recreate patterns using cubes. With several different playing modes and an intuitive design, the game is perfect for testing puzzle-solving skills against friends and family. Developed as part of the CS 319 Object-Oriented Software Engineering course project at Bilkent University, Q-Bitz features a powerful JavaFX user interface that allows for seamless and intuitive gameplay. With its advanced graphics and engaging gameplay, Q-Bitz represents a major breakthrough in the field of puzzle gaming, providing players with a fun and immersive experience.",
		techStack: ["JavaFX"],
		githubLink: "https://github.com/ztancankiri/Q-Bitz",
		date: "2018",
	},
];

const Projects = () => {
	return (
		<Layout title="Projects">
			<Typography sx={{ my: 5 }}>Below is the list of my finished projects and miscellaneous works sorted by their dates.</Typography>
			<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} sx={{ mr: 2 }}>
				{projects.map((project) => (
					<ProjectCard key={project.title} project={project} />
				))}
			</Stack>
		</Layout>
	);
};

export default Projects;
