import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";

import CustomIcon from "../components/CustomIcon";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

import "@react-pdf-viewer/core/lib/styles/index.css";

import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import Layout from "../components/Layout";

const CV_PATH = "/documents/ZAFER_TAN_CANKIRI_CV.pdf";

const Cv = () => {
	return (
		<Layout title="CV">
			<Typography sx={{ my: 2, pr: 3, textAlign: "justify" }}>Below is my up-to-date CV.</Typography>
			<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} sx={{ mr: 2 }}>
				<Button variant="outlined" href={CV_PATH} target="_blank" sx={{ width: 180 }}>
					<CustomIcon icon={faFileDownload} size="lg" sx={{ mr: 2 }} />
					Download CV
				</Button>
				<Box sx={{ width: { xs: `calc(100vw - 50px)`, sm: "615px" }, alignContent: "left" }}>
					<Worker workerUrl={pdfjsWorker}>
						<Viewer fileUrl={CV_PATH} defaultScale={SpecialZoomLevel.PageWidth} />
					</Worker>
				</Box>
			</Stack>
		</Layout>
	);
};

export default Cv;
