import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./assets/styles/Theme";
import CssBaseline from "@mui/material/CssBaseline";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Cv from "./pages/Cv";

import "./assets/styles/styles.css";

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Router>
				<Routes>
					<Route path="/" element={<About />} />
					<Route path="/about" element={<About />} />
					<Route path="/projects" element={<Projects />} />
					<Route path="/cv" element={<Cv />} />
				</Routes>
			</Router>
		</ThemeProvider>
	);
};

export default App;
