import React from "react";
import { Typography } from "@mui/material";

import { Masonry } from "@mui/lab";

import Layout from "../components/Layout";
import EducationCard from "../components/EducationCard";
import WorkCard from "../components/WorkCard";
import ExtraCard from "../components/ExtraCard";
import PublicationCard from "../components/PublicationCard";
import LanguageCard from "../components/LanguageCard";

const summary =
	"Zafer Tan Çankırı, a Bilkent University CS grad, innovator, and ex-Bilkent IEEE Robotics member, interned at Reengen Energy and Fraunhofer IAO in IoT. He's worked at MilSOFT and Fraunhofer FKIE in military defense, and is now with Dreamlines GmbH as a backend software developer, pursuing a Master’s in Informatics at the Technical University of Munich.";

const About = () => {
	return (
		<Layout title="About">
			<Typography sx={{ my: 5, pr: 3, textAlign: "justify" }}>{summary}</Typography>
			<Masonry columns={{ xs: 1, sm: 1, md: 2 }} spacing={2}>
				<WorkCard />
				<EducationCard />
				<PublicationCard />
				<ExtraCard />
				<LanguageCard />
			</Masonry>
		</Layout>
	);
};

export default About;
