import React from "react";
import { Box, Divider, Typography, Stack } from "@mui/material";
import { Markdown } from "../utils/Markdown";

const work_experiences = [
	{
		company: "Dreamlines GmbH",
		location: "Hamburg, Germany",
		position: "Software Engineer",
		start_date: "2022",
		end_date: "",
		descriptions: [
			'<Font size="13" align="justify">• Solo-managed and updated the company’s legacy Node.js B2B solution until its end-of-life, a role previously filled by a team. Also fixed bugs to maintain compatibility with migrated microservices.</Font>',
			'<Font size="13" align="justify">• Integrated partner systems, including cruise operators, using APIs, FTP, email, and cloud storage across GCP, AWS, etc. Developed parsers for various data formats like XML, JSON, and Excel, ensuring continuous data flow.</Font>',
			'<Font size="13" align="justify">• Replaced outdated microservice implementations with new solutions and created monitoring systems for data integrity.</Font>',
			'<Font size="13" align="justify">• Audited microservice databases for errors, fixed inaccuracies from partner parser bugs using Python scripts, and resolved parser issues.</Font>',
		],
	},
	// {
	// 	company: "droov.io",
	// 	location: "Munich, Germany",
	// 	position: "IDP Project - Frontend Developer",
	// 	start_date: "July 2022",
	// 	end_date: "March 2023",
	// 	descriptions: ['<Font size="13" align="justify">Worked as a frontend developer within the scope of an interdisciplinary project.</Font>'],
	// },
	{
		company: "Fraunhofer FKIE",
		location: "Wachtberg, Germany",
		position: "Software Engineer, R&D",
		start_date: "2020",
		end_date: "2022",
		descriptions: [
			'<Font size="13" align="justify">• Worked for "Hybride Bedrohungen (Hybrid Threads)" project. Designed and developed adapter and aggregator services to collect and analyze data from open data sources of various infrastructures like transportation and energy supply chains.</Font>',
			'<Font size="13" align="justify">• Worked on the "NATO Generic Vehicle Architecture (NGVA)" project. Experimented with the products of different Data Distribution Service (DDS) vendors, and created prototypes to test the interoperability of these products to create a P2P communication among different components (embedded devices and single board computers) of a military vehicle.</Font>',
		],
	},
	{
		company: "Milsoft Software Technologies",
		location: "Ankara, Turkey",
		position: "Software Engineer",
		start_date: "2020",
		end_date: "2020",
		descriptions: ['<Font size="13" align="justify">Worked as a Full-Time Backend Software Engineer for Combat Management Systems (Mil-CMS) at MilSOFT.</Font>'],
	},
	{
		company: "Fraunhofer IAO",
		location: "Stuttgart, Germany",
		position: "Software Development Intern",
		start_date: "2019",
		end_date: "2019",
		descriptions: [
			'<Font size="13" align="justify">Developed a sensor node network with a big data warehouse for the company as an embedded software developer. The sensor network collects environmental data and delivers it to the big data warehouse to provide raw data for AI technologies to build a smart office environment. ESP32 and Raspberry Pi are used as hardware and C++, Node.js, and Python are used for programming.</Font>',
		],
	},
	{
		company: "Reengen Energy",
		location: "Istanbul, Turkey",
		position: "Software Development Intern",
		start_date: "2018",
		end_date: "2018",
		descriptions: [
			'<Font size="13" align="justify">Developed Automatic Reporting Application for the company as a full-stack developer. The application collects data from the company\'s platform and other data sources online, then using these data it generates monthly analysis reports for the customers. The backend is developed with Java. The front end is developed with Vanilla Javascript. Python is used for the data analysis module.</Font>',
		],
	},
];

const WorkCard = ({ sx }) => (
	<Box sx={{ border: 2, borderRadius: 2, ...sx }}>
		<Box sx={{ p: 2 }}>
			<Typography sx={{ fontSize: 25 }}>Work Experiences</Typography>
		</Box>

		<Divider />

		{work_experiences.map((experience, experience_index) => (
			<Box key={`WorkExperience-${experience_index}`}>
				<Box sx={{ p: 2 }}>
					<Stack direction={{ sm: "column", md: "row" }} justifyContent="space-between" alignItems="center">
						<Typography sx={{ fontWeight: "bold" }}>
							{experience.company}, {experience.location}
						</Typography>
						<Typography sx={{ px: 1, py: 0.3, borderRadius: 1, backgroundColor: "#E7E7E7", fontStyle: "italic", fontSize: 13 }}>
							{experience.start_date !== experience.end_date && experience.end_date?.length > 0 && `${experience.start_date} - ${experience.end_date}`}
							{experience.start_date !== experience.end_date && experience.end_date?.length === 0 && `${experience.start_date} - Present`}
							{experience.start_date === experience.end_date && `${experience.start_date}`}
						</Typography>
					</Stack>

					<Stack direction={{ sm: "column", md: "row" }} justifyContent="space-between" alignItems="center">
						<Typography sx={{ fontStyle: "italic" }}>{experience.position}</Typography>
					</Stack>

					{experience.descriptions.map((description, description_index) => (
						<Stack
							key={`WorkExperience-${experience_index}-description-${description_index}`}
							direction="row"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={0.5}
							sx={{ pl: { sm: 0, md: 4 } }}
						>
							<Markdown>{description}</Markdown>
						</Stack>
					))}
				</Box>

				{experience_index !== work_experiences.length - 1 && <Divider />}
			</Box>
		))}
	</Box>
);

export default WorkCard;
