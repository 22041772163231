import React from "react";
import { Box, Typography, List, ListItemButton, ListItemIcon, ListItemText, Avatar, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { faLinkedin, faGithub, faXing, faGoogleScholar } from "@fortawesome/free-brands-svg-icons";
import { faUser, faCode, faEnvelope, faFile } from "@fortawesome/free-solid-svg-icons";

import ProfilePhoto from "../assets/images/profile_photo.jpg";

import CustomIconButton from "./CustomIconButton";
import CustomIcon from "./CustomIcon";

const grayColor = "#E7E7E7";
const blueColor = "#0E5484";

const profilePhotoLargeSize = 200;
const profilePhotoSmallSize = 140;

const drawerWidth = 240;
const drawerHeight = 160;

const Sidebar = () => {
	const isLargeWindow = useMediaQuery(useTheme().breakpoints.up("sm"));
	const drawerStyles = {
		width: { xs: "100%", sm: drawerWidth },
		height: { xs: drawerHeight, sm: "100%" },
		"& .MuiDrawer-paper": {
			width: { xs: "100%", sm: drawerWidth },
			height: { xs: drawerHeight, sm: "100%" },
			boxShadow: "none",
			background: grayColor,
		},
	};

	return (
		<Drawer variant="permanent" anchor={isLargeWindow ? "left" : "top"} sx={drawerStyles}>
			{isLargeWindow && (
				<Box>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", p: 2 }}>
						<Avatar src={ProfilePhoto} sx={{ width: profilePhotoLargeSize, height: profilePhotoLargeSize }} alt="Zafer Tan Cankiri" />
					</Box>

					<Box sx={{ ml: 2 }}>
						<Box sx={{ display: "flex", alignItems: "left", justifyContent: "left" }}>
							<Typography noWrap color={blueColor} sx={{ fontSize: 20, fontWeight: "bold" }}>
								ZAFER TAN CANKIRI
							</Typography>
						</Box>
						<Box sx={{ display: "flex", alignItems: "left", justifyContent: "left" }}>
							<Typography noWrap sx={{ fontSize: 18, mb: 2 }}>
								Software Engineer
							</Typography>
						</Box>
					</Box>

					<List>
						<ListItemButton component={Link} to="/about" sx={{ mx: 1, borderRadius: 2 }}>
							<ListItemIcon sx={{ minWidth: "auto", mr: 4 }}>
								<CustomIcon icon={faUser} size="lg" sx={{ color: blueColor }} />
							</ListItemIcon>
							<ListItemText primary="About" />
						</ListItemButton>
						<ListItemButton component={Link} to="/projects" sx={{ mx: 1, borderRadius: 2 }}>
							<ListItemIcon sx={{ minWidth: "auto", mr: 4 }}>
								<CustomIcon icon={faCode} size="lg" sx={{ color: blueColor }} />
							</ListItemIcon>
							<ListItemText primary="Projects" />
						</ListItemButton>
						<ListItemButton component="a" href="mailto:ztan.cankiri@gmail.com" target="_blank" sx={{ mx: 1, borderRadius: 2 }}>
							<ListItemIcon sx={{ minWidth: "auto", mr: 4 }}>
								<CustomIcon icon={faEnvelope} size="lg" sx={{ color: blueColor }} />
							</ListItemIcon>
							<ListItemText primary="Email" />
						</ListItemButton>
						<ListItemButton component={Link} to="/cv" sx={{ mx: 1, borderRadius: 2 }}>
							<ListItemIcon sx={{ minWidth: "auto", mr: 4 }}>
								<CustomIcon icon={faFile} size="lg" sx={{ color: blueColor }} />
							</ListItemIcon>
							<ListItemText primary="CV" />
						</ListItemButton>
						<ListItemButton component="a" href="https://www.linkedin.com/in/ztancankiri" target="_blank" sx={{ mx: 1, borderRadius: 2 }}>
							<ListItemIcon sx={{ minWidth: "auto", mr: 4 }}>
								<CustomIcon icon={faLinkedin} size="lg" sx={{ color: blueColor }} />
							</ListItemIcon>
							<ListItemText primary="LinkedIn" />
						</ListItemButton>
						<ListItemButton component="a" href="https://xing.to/ztancankiri" target="_blank" sx={{ mx: 1, borderRadius: 2 }}>
							<ListItemIcon sx={{ minWidth: "auto", mr: 4 }}>
								<CustomIcon icon={faXing} size="lg" sx={{ color: blueColor }} />
							</ListItemIcon>
							<ListItemText primary="XING" />
						</ListItemButton>
						<ListItemButton component="a" href="https://github.com/ztancankiri" target="_blank" sx={{ mx: 1, borderRadius: 2 }}>
							<ListItemIcon sx={{ minWidth: "auto", mr: 4 }}>
								<CustomIcon icon={faGithub} size="lg" sx={{ color: blueColor }} />
							</ListItemIcon>
							<ListItemText primary="Github" />
						</ListItemButton>

						<ListItemButton component="a" href="https://scholar.google.com/citations?user=BzwwdykAAAAJ" target="_blank" sx={{ mx: 1, borderRadius: 2 }}>
							<ListItemIcon sx={{ minWidth: "auto", mr: 4 }}>
								<CustomIcon icon={faGoogleScholar} size="lg" sx={{ color: blueColor }} />
							</ListItemIcon>
							<ListItemText primary="Google Scholar" />
						</ListItemButton>
					</List>
				</Box>
			)}
			{!isLargeWindow && (
				<Box>
					<Stack direction="row" spacing={1}>
						<Box sx={{ display: "flex", alignItems: "left", justifyContent: "left", p: 1 }}>
							<Avatar src={ProfilePhoto} sx={{ width: profilePhotoSmallSize, height: profilePhotoSmallSize }} alt="Zafer Tan Cankiri" />
						</Box>
						<Box sx={{ pt: 2 }}>
							<Typography noWrap color={blueColor} sx={{ fontSize: 18, fontWeight: "bold" }}>
								ZAFER TAN CANKIRI
							</Typography>
							<Typography noWrap sx={{ fontSize: 16 }}>
								Software Engineer
							</Typography>

							<Box sx={{ mt: 0.5 }}>
								<CustomIconButton icon={faUser} color={blueColor} size="small" iconSize="lg" component={Link} to="/about" />
								<CustomIconButton icon={faCode} color={blueColor} size="small" iconSize="lg" component={Link} to="/projects" />
								<CustomIconButton icon={faEnvelope} color={blueColor} size="small" iconSize="lg" component="a" href="mailto:ztan.cankiri@gmail.com" />
								<CustomIconButton icon={faFile} color={blueColor} size="small" iconSize="lg" component={Link} to="/cv" />
							</Box>
							<Box sx={{ mt: 0.5 }}>
								<CustomIconButton icon={faLinkedin} color={blueColor} size="small" iconSize="lg" component="a" href="https://www.linkedin.com/in/ztancankiri" />
								<CustomIconButton icon={faXing} color={blueColor} size="small" iconSize="lg" component="a" href="https://xing.to/ztancankiri" />
								<CustomIconButton icon={faGithub} color={blueColor} size="small" iconSize="lg" component="a" href="https://github.com/ztancankiri" />
								<CustomIconButton icon={faGoogleScholar} color={blueColor} size="small" iconSize="lg" component="a" href="https://scholar.google.com/citations?user=BzwwdykAAAAJ" />
							</Box>
						</Box>
					</Stack>
				</Box>
			)}
		</Drawer>
	);
};

export default Sidebar;
