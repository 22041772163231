import React from "react";
import { Box, Divider, Typography, Stack } from "@mui/material";
import { Markdown } from "../utils/Markdown";

const educations = [
	{
		school: "Technical University of Munich",
		location: "Munich, Germany",
		degree: "Master of Science",
		field: "Informatics",
		start_date: "2021",
		end_date: "2024",
		grade: "GPA: 1.8 / 5.0 (1.0 highest)",
		additional: ['<Font size="13">**Thesis:** Modelling Network Patterns of Sensor Data in Autonomous Driving</Font>'],
	},
	{
		school: "Bilkent University",
		location: "Ankara, Turkey",
		degree: "Bachelor of Science",
		field: "Computer Engineering",
		start_date: "2016",
		end_date: "2020",
		grade: "GPA: 3.55 / 4.00 (4.0 highest)",
		additional: [
			'<Font size="13">• Awarded with scholarship by Bilkent University.</Font>',
			'<Font size="13">• Placed on Dean’s High Honour List at Bilkent University.</Font>',
			'<Font size="13">• Won the Best Senior Design Project Award at Bilkent University.</Font>',
		],
	},
];

const EducationCard = ({ sx }) => (
	<Box sx={{ border: 2, borderRadius: 2, ...sx }}>
		<Box sx={{ p: 2 }}>
			<Typography sx={{ fontSize: 25 }}>Education</Typography>
		</Box>

		<Divider />

		{educations.map((education, education_index) => (
			<Box key={`Education-${education_index}`}>
				<Box sx={{ p: 2 }}>
					<Stack direction={{ sm: "column", md: "row" }} justifyContent="space-between" alignItems="center">
						<Typography sx={{ fontWeight: "bold" }}>
							{education.school}, {education.location}
						</Typography>
						<Typography sx={{ px: 1, py: 0.3, borderRadius: 1, backgroundColor: "#E7E7E7", fontStyle: "italic", fontSize: 13 }}>
							{education.start_date} - {education.end_date}
						</Typography>
					</Stack>

					<Stack direction={{ sm: "column", md: "row" }} justifyContent="space-between" alignItems="center">
						<Typography sx={{ fontStyle: "italic" }}>
							{education.degree}, {education.field}
						</Typography>
					</Stack>

					<Stack direction={{ sm: "column", md: "row" }} justifyContent="space-between" alignItems="center">
						<Typography sx={{ fontStyle: "italic", fontSize: 13 }}>{education.grade}</Typography>
					</Stack>

					{education.additional.map((info, info_index) => (
						<Stack key={`Education-${education_index}-info-${info_index}`} direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0.5} sx={{ pl: { sm: 0, md: 4 } }}>
							<Markdown>{info}</Markdown>
						</Stack>
					))}
				</Box>

				{education_index !== educations.length - 1 && <Divider />}
			</Box>
		))}
	</Box>
);

export default EducationCard;
