import React from "react";
import { Box, Divider, Typography } from "@mui/material";

const LanguageCard = ({ sx }) => (
	<Box sx={{ border: 2, borderRadius: 2, ...sx }}>
		<Box sx={{ p: 2 }}>
			<Typography sx={{ fontSize: 25 }}>Languages</Typography>
		</Box>

		<Divider />

		<Box sx={{ p: 2 }}>
			<Typography sx={{ fontSize: 20, textAlign: "justify" }}>Turkish: Native</Typography>
			<Typography sx={{ fontSize: 20, textAlign: "justify" }}>English: Fluent</Typography>
			<Typography sx={{ fontSize: 20, textAlign: "justify" }}>German: Beginner</Typography>
		</Box>
	</Box>
);

export default LanguageCard;
