import React from "react";
import { Box, Divider, Typography, Stack } from "@mui/material";

const extra_activities = [
	{
		name: "Bilkent IEEE Robotics and Automation Society",
		position: "Presenter in Automation Chair",
		start_date: "2016",
		end_date: "2020",
		descriptions: ["Gave seminars and conducted workshops about creating home automation systems by using basic development boards like Raspberry Pi, Arduino, and ESP Microcontrollers."],
	},
];

const ExtraCard = ({ sx }) => (
	<Box sx={{ border: 2, borderRadius: 2, ...sx }}>
		<Box sx={{ p: 2 }}>
			<Typography sx={{ fontSize: 25 }}>Extracurricular Activities</Typography>
		</Box>

		<Divider />

		{extra_activities.map((activity, activity_index) => (
			<Box key={`Activity-${activity_index}`}>
				<Box sx={{ p: 2 }}>
					<Stack direction={{ sm: "column", md: "row" }} justifyContent="space-between" alignItems="center">
						<Typography sx={{ fontWeight: "bold" }}>{activity.name}</Typography>
						<Typography sx={{ px: 1, py: 0.3, borderRadius: 1, backgroundColor: "#E7E7E7", fontStyle: "italic", fontSize: 13 }}>
							{activity.start_date} - {activity.end_date}
						</Typography>
					</Stack>

					<Stack direction={{ sm: "column", md: "row" }} justifyContent="space-between" alignItems="center">
						<Typography sx={{ fontStyle: "italic" }}>{activity.position}</Typography>
					</Stack>

					{activity.descriptions.map((description, description_index) => (
						<Stack
							key={`ExtraActivity-${activity_index}-description-${description_index}`}
							direction="row"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={0.5}
							sx={{ pl: { sm: 0, md: 4 } }}
						>
							<Typography sx={{ fontSize: 13, textAlign: "justify" }}>{description}</Typography>
						</Stack>
					))}
				</Box>

				{activity_index !== extra_activities.length - 1 && <Divider />}
			</Box>
		))}
	</Box>
);

export default ExtraCard;
